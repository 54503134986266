.catalog-tableview-service-title {
  display: flex;
  gap: 0.438rem;
  align-items: center;
}

.catalog-tableview-service-title-container {
  display: flex;
  flex-direction: column;
  gap: 0.438rem;
  align-self: center;
}
.catalog-tableview-plan {
  font-size: 0.813rem !important;
}

.catalog-tableview-service-logo {
  margin: 0.625rem 0.75rem;
  width: 4rem;
  height: 4rem;
  object-fit: contain;
}
.catalog-tableview-service-alert {
  color: #3cc13bde !important;
}
.catalog-tableview-service-subtitle-container {
  display: flex;
  gap: 0.625rem;
}
.catalog-tableview-service-subtitle {
  margin-top: 0.438rem !important;
  width: 3.125rem;
}
.catalog-tableview-service-chip-yellow {
  border: 0.063rem solid #febb14 !important;
  border-radius: 0.25rem !important;
  background-color: #fff8e8 !important;
  color: #8c670b !important;
  font-style: italic;
  font-size: 0.875rem !important;
  font-weight: 700;
  width: 9.375rem;
}

.catalog-tableview-service-chip-green {
  border: 0.063rem solid #3cc13b !important;
  border-radius: 0.25rem !important;
  background-color: #ecf9eb !important;
  color: #216a20 !important;
  font-style: italic;
  font-size: 0.875rem !important;
  font-weight: 700;
  width: 7.5rem;
}
