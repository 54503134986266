.carousleSlideHolder {
  display: flex;
  flex-direction: row;
}
.carousleSlideImage {
  height: 43.75rem;
  width: 31.25rem;
}

.carousleContainer {
  width: 80%;
  margin-left: 8%;
  margin-top: 3.125rem;
}
.carousel .thumb {
  width: 5%;
  height: 3.125rem;
}
.carousel {
  text-align: center;
}
.carousel .thumb img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
