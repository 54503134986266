.title {
  color: var(--md-ref-palette-neutral900, var(--neutral900));
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.01125rem;
  margin-top: 2rem;
}
.smallTitle {
  font-size: 1.25rem;
  line-height: 1.5rem;
}
.subTitle {
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.009375rem;
  margin: 2rem 0;
}
.grayText {
  color: var(--md-sys-color-Surface-on-surface-low, var(--neutral600));
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
}
.emptySection {
  background-color: var(--neutral800);
}
.errorText {
  color: var(--alertPrimary);
  font-size: 0.875rem;
}
.disabledInput {
  height: 2.5rem;
}
.boldText {
  color: #000;
  font-size: 1rem;
  font-weight: 700;
  line-height: 150%;
}
.content {
  display: flex;
  color: var(--md-sys-color-Surface-on-surface-high, var(--neutralBlack));
  font-size: 1rem;
  font-weight: 400;
  column-gap: 0.3125rem;
  align-items: center;
  margin-bottom: 0.25rem;
}
.smallText {
  color: var(--md-sys-color-Surface-on-surface-high, var(--neutralBlack));
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  grid-column: 1/-1;
}
.required {
  color: var(--md-sys-color-Alert-alert, var(--alertPrimary));
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.025rem;
}
.parameterContainer {
  margin: 1.5rem 0;
}
.tryNowButton {
  display: flex;
  padding: 0.75rem 1.5rem;
  align-self: center;
  gap: 1rem;
  border-radius: 0.25rem;
  color: white;
  background-color: var(--md-sys-color-Primary-primary, var(--primary));
}
.grayBox,
.smallGrayBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 0.063rem solid
    var(--md-sys-color-Borders-border-disabled-medium, var(--neutral400));
  background: var(--md-ref-palette-Neutral-neutral100, var(--neutral100));
}
.grayBox {
  margin-bottom: 6rem;
  padding: 1rem;
}
.grayBox,
.smallGrayBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 0.063rem solid
    var(--md-sys-color-Borders-border-disabled-medium, var(--neutral400));
  background: var(--md-ref-palette-Neutral-neutral100, var (--neutral100));
  gap: 1.5rem;
}
.smallGrayBox {
  padding: 0.1875rem 1rem;
  color: var(--neutral800);
  display: inline;
  font-size: 0.75rem;
}
.errorBanner,
.successBanner,
.loadingBanner {
  display: flex;
  padding: 0.625rem 1rem;
  justify-content: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: 0.25rem;
}
.successBanner {
  background: var(--md-ref-palette-Success100, var(--success100));
}
.errorBanner {
  background: var(--md-ref-palette-Success100, var(--alert400));
}
.loadingBanner {
  background: var(--md-ref-palette-Success100, var(--warning400));
}
.rotatedT {
  display: flex;
  transform: rotate(90deg);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  width: 1rem;
  height: 1.5rem;
  justify-content: center;
}
.outputTypeContainer {
  display: flex;
  width: 100%;
  gap: 7.5rem;
}
.outputTypeLabel {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
}
