.minicard-input-radio {
  margin-right: 0.5rem;
}

.minicard {
  display: inline-block;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.75rem 1.5rem;
  position: relative;
  width: 18.75rem;
  cursor: pointer;
  height: 13.125rem;
  background: #f6fdff;
  border-radius: 0.5rem;
  border: 0.125rem solid var(--md-sys-color-on-surface-disabled, #90a8b5);
  background: var(--md-sys-color-primary-on-primary-high, #f6fdff);
  box-shadow:
    0 0.063rem 0.313rem 0 rgba(2, 39, 60, 0.2),
    0 0.188rem 0.063rem 0 rgba(2, 39, 60, 0.12),
    0 0.125rem 0.125rem 0 rgba(2, 39, 60, 0.14);
  font-family: "Roboto", sans-serif;
  /* margin-right: 1.875rem; */
}

.minicard-add-instance {
  display: inline-block;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.75rem 1.5rem;
  position: relative;
  width: 18.75rem;
  cursor: pointer;
  height: 13.125rem;
  background: #f6fdff;
  border-radius: 0.5rem;
  border: 0.125rem dashed var(--md-sys-color-on-surface-disabled, #90a8b5);
  background: var(--md-sys-color-primary-on-primary-high, #f6fdff);
}

.minicard-add-instance > h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 2.125rem;
  line-height: 2.25rem;
  color: #0a85c7;
  margin: 0;
}

.minicard-add-instance > p {
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.03333em;
  color: #02273c;
  margin-top: -0.938rem;
}

.rec.rec-arrow.rec.rec-arrow {
  color: #0a85c7;
  background-color: #edf4f8;
}

.rec.rec-carousel-wrapper .rec-pagination .rec.rec-dot.rec.rec-dot_active {
  background: #0a85c7;
  box-shadow: 0 0 0.063rem 0.188rem #0a85c7;
}

.rec .rec-item-wrapper {
  width: 110% !important;
  padding-right: 0.625rem !important;
}

.rec .rec-carousel-wrapper {
  width: 10% !important;
}

.minicard:hover {
  border-radius: 0.5rem;
  border: 0.25rem solid var(--md-sys-color-primary-primary, #0a85c7);
  background: var(--md-ref-palette-secondary-50, #ebf8fd);
  /* md/ref/elevation/12dp */
  box-shadow:
    0 0.438rem 0.5rem 0 rgba(2, 39, 60, 0.2),
    0 0.313rem 1.375rem 0 rgba(2, 39, 60, 0.12),
    0 0.75rem 1.063rem 0 rgba(2, 39, 60, 0.14);
}

.minicard:focus {
  border-radius: 0.5rem;
  border: 0.25rem solid var(--md-sys-color-primary-primary, #0a85c7);
  background: var(--md-ref-palette-secondary-50, #ebf8fd);
  /* md/ref/elevation/12dp */
  box-shadow:
    0 0.438rem 0.5rem 0 rgba(2, 39, 60, 0.2),
    0 0.313rem 1.375rem 0 rgba(2, 39, 60, 0.12),
    0 0.75rem 1.063rem 0 rgba(2, 39, 60, 0.14);
}

.minicard > h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 2.125rem;
  line-height: 2.25rem;
  color: #0a85c7;
  margin: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.minicard > h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.016rem;
  color: #0a85c7;
  margin: 0;
  margin-bottom: 0.625rem;
}

.minicard > h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.minicard > p {
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.03333em;
  color: #02273c;
  margin: 0;
}

.minicard > span {
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.016rem;
  color: #02273c;
  margin: 0;
  margin-top: 0.5rem;
}

.icons-container {
  float: right;
}

.icons-des-container {
  display: flex;
}

.icons-des-container > p {
  color: #0a85c7;
  font-size: 0.75rem;
  font-weight: 500;
  margin-top: -0.188rem;
}

.PC-manage-icon {
  margin-left: 0.938rem;
  margin-right: 1.25rem;
}

.PC-manage-text {
  margin-left: 0.438rem;
  margin-right: 0.938rem;
}

.carousel-wrapper {
  padding-right: 2vw;
}
