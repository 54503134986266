.PC-header {
  background-image: url("../../../../../assets/PrivateAPI.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 3vh 5vh;
  min-height: 45vh;
  display: flex;
  gap: 6vw;
  width: -webkit-fill-available;

  @media screen and (max-aspect-ratio: 1/1.5) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  @media only screen and (max-width: 1240px) {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
}

.pc-learnmore-button {
  font-weight: 500;
  min-height: 6.5vh;
  width: 13vw;
  font-size: 2.5vh !important;

  @media only screen and (max-width: 1240px) {
    width: 20vw;
    font-size: 2vh !important;
  }
}

.pc-learnmore-button:hover {
  background-color: #33bde9 !important;
}

.apiCatalogHeaderMainTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 2vh;

  @media only screen and (max-aspect-ratio: 1/1.5) {
    text-align: center;
  }

  @media only screen and (max-width: 1240px) {
    text-align: center;
  }
}

.apiCatalogSubHeader {
  font-size: 3vh;
  width: 35vw;
  line-height: 4vh;
  color: #355263;
  font-weight: 650;
  padding-bottom: 2vh;

  @media only screen and (max-width: 1240px) {
    font-size: 28px;
    width: 90%;
    line-height: 1;
  }
}

.apiCatalogBody {
  font-size: 1.7vh;
  width: 35vw;
  line-height: 3vh;
  position: relative;
  top: -3vh;

  @media only screen and (max-width: 1240px) {
    font-size: 16px;
    line-height: 2;
    width: 100%;

    text-align: center;
  }

  @media only screen and (max-aspect-ratio: 1/1.5) {
    width: 100%;
    padding-bottom: 20px;
  }

  @media only screen and (max-width: 900px) {
    width: 100%;
    padding-bottom: 20px;
  }
}

.imgContainer {
  display: flex;
  flex-direction: column;
  width: fit-content;
  color: #0a85c7;
  margin: auto;

  @media only screen and (max-aspect-ratio: 1/1.5) {
    width: max-content;
  }
}

.apiCatalogHeader {
  font-size: 4vh;
  line-height: 1;
  padding-left: 0.7vw;
  position: relative;

  @media only screen and (max-aspect-ratio: 1/1.5) {
    display: none;
  }
}

.PC-headerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  height: 100%;

  @media only screen and (max-width: 1240px) {
    flex-direction: row;
    align-items: center;
  }

  @media only screen and (max-width: 900px) {
    justify-content: unset;
    flex-direction: column;
    align-items: unset;
  }

  @media only screen and (max-aspect-ratio: 1/1.5) {
    height: min-content;
    flex-direction: row;
    display: block;
  }
}

.datameshApiLogo {
  height: 20vw;

  @media only screen and (max-aspect-ratio: 1/1.5) {
    height: 25vh;
  }
}
.sectionContainer {
  margin-bottom: 1rem;
}
