
.pricing-card-container{
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.25rem;
  gap: 2.625rem;
  padding: 0.625rem 0;
  flex: 100%;
  flex-wrap: wrap;
  position: relative;
}

.pricing-standard-img{
  width: 1.813rem;
  height: 1.25rem;
  margin-right: 0.625rem;
  margin-left: 1.875rem;
  margin-top: 0.5rem;
}

.pricing-check-circle-img{
  margin-right: 0.625rem;
}

.padding1rem{
  padding: 1rem;
}

.algo-performance-provision-container{
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  margin-left: 1.25rem;
  width: calc(100% - 2.5rem);

}

.algo-performance-header-img {
  width: 7.813rem;
  height: 7.5rem;
  margin-right: 1.25rem;
  margin-left: -0.625rem;

}