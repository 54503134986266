.cardBannerContainer {
  .cardContentContainer {
    .cardTextSection {
      .title {
        font-size: 1.25rem;
        display: flex;
        gap: 0.8rem;
        color: black;
      }

      .subTitle {
        margin-top: 0.313rem;
        font-size: 1rem;
      }
    }

    .descrition {
      margin-top: 0.938rem;
      line-height: 1.25rem;
      font-size: 0.875rem;
    }
  }
}
