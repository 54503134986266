.myServicesSupersetHeader {
  margin-left: 1.063rem;
  height: auto;
  box-shadow: none !important;
}

.myServicesSupersetHeaderContent {
  display: flex;
  margin-top: 1.5rem;
  gap: 0.5rem;
  padding-bottom: 3.25rem;
}

.myServicesSupersetHeaderLogo {
  height: 5rem;
  width: 10rem;
  margin-right: 1.25rem;
  margin-left: -0.625rem;
}

.myServicesSupersetTitleContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.myServicesSupersetTitle {
  display: flex;
  flex-direction: row;
}

.myServicesSupersetServiceName {
  margin-top: 0.313rem;
  margin-right: 1rem;
  font-size: 2.125rem !important;
  font-weight: 600 !important;
  color: #0468b1 !important;
}

.myServiceSupersetFuntion {
  background-color: #e7f3f9 !important;
  color: #0a85c7 !important;
}

.myServiceSupersetIndustry {
  background-color: #efe6fd !important;
  color: #6200ee !important;
}

.myServiceSupersetServiceType {
  background-color: #ffe5f0 !important;
  color: #d72f77 !important;
}

.myServiceSupersetVersion {
  margin-left: 0.125rem;
  margin-top: 0.5rem;
  font-weight: 600 !important;
  font-size: 1.25rem !important;
}

.myServiceSupersetHeaderDescription {
  margin-left: 0.125rem !important;
  margin-top: 0.313rem !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  width: 50.188rem;
  line-height: 1.5 !important;
}

.myServiceSupersetHeaderButtonContainer {
  display: flex;
  gap: 1rem;
}
.myServiceSupersetHeaderButton {
  font-weight: 600 !important;
  font-size: 1.25rem !important;
  padding: 0.75rem 1.5rem !important;
  color: #f6fdff;
  align-items: center;
  justify-content: center;
  background-color: #0a85c7 !important;
}

.myServiceSupersetHeaderSupportButton {
  font-size: 1.25rem !important;
  font-weight: 600 !important;
  border: 0.25rem solid !important;
}

.myServiceSupersetOverview {
  display: flex;
  flex-direction: column;
  gap: 2.438rem;
}
