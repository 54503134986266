.plansFeaturesList {
  padding: 1rem;
}

.documentationHeading {
  font-size: 2.125rem;
  font-weight: 600;
}

.documentationDescription {
  font-size: 1rem;
  font-weight: 400;
}

.createInstanceBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.createInstanceBtnHeader {
  display: flex;
  align-items: center;
  padding: 0rem 10rem;
  gap: 1rem;
}

.catalogSupersetContainer {
  display: flex;
  gap: 3rem;
  flex-direction: column;
}

.catalogSuperset {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.catalogSupersetOverviewTitle {
  display: flex;
  padding: 0.625rem 0;
  flex-direction: column;
}

.catalogSupersetPricingPlan {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.alignItemsCenter {
  align-items: center;
}

.provisionContainer {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  margin-left: 1.25rem;
  width: calc(100%-2.5rem);
}

.regionAvailabilityContainer {
  display: flex;
  gap: 2rem;
  flex-direction: column;
  margin-left: 1.25rem;
  align-items: flex-start;
}

.specificRegionDetils {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  padding: 1rem 1.5rem;
  width: calc(100%-2.5rem);
  background-color: #edf4f8;
  border: 0.063rem solid #cbd9e1;
  border-radius: 0.25rem;
}

.regionListli {
  font-size: 1rem;
  padding: 0.5rem 0.313rem;
  color: var(--neutral700);
}

.catalogSupersetRelatedProducts {
  width: 100%;
}
