.riskthinking-logo {
  width: 12rem !important;
  height: 2.5rem !important;
  margin-top: 1rem;
}
.custom-riskthinking-logo img {
  width: 11rem !important;
}
.riskthinkingHeaderBtnCont {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  align-items: center;
  margin-left: 14rem;
}
.custom-riskthinking-logo h1 {
  line-height: 1.15 !important;
}
.custom-general-logo img {
  width: 10.25rem;
  height: 5.3125rem;
}

tr:has(.addedCompany) {
  background-color: var(--neutral200) !important;
}
