.PC-overview-container {
  display: flex;
  gap: 3rem;
  flex-direction: column;
}

.PC-service-overview {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.PC-service-overview-region {
  display: flex;
  gap: 3.125rem;
}

.PC-overview-central {
  width: 30%;
}

.PC-overview-central:nth-child(2) {
  width: 35%;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  border-left: 0.125rem solid #cbd9e1;
  border-right: 0.125rem solid #cbd9e1;
}

.PC-API-service {
  margin-top: 5rem;
  display: flex;
  gap: 2.5rem;
}

.PC-API-service-logo {
  width: 45%;
  height: 45%;
}

.PC-API-service-des {
  width: 45%;
}

.PC-billing {
  margin-top: 5rem;
  display: flex;
  gap: 2.5rem;
}

.PC-billing-info {
  width: 45%;
}

.PC-billing-logo {
  width: 45%;
  height: 45%;
}

.PC-relevant-resources {
  display: flex;
  gap: 2.5rem;
}

.PC-resouce-cards {
  display: flex;
}

.PC-resource-text-header {
  display: flex;
  gap: 0.938rem;
}

.PC-resource-img {
  width: 11.25rem;
  height: 10rem;
}
