.apiiKeysContainer {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  width: calc(100% - 2.5rem);
}
.apiKeysContent {
  display: flex;
  gap: 3rem;
  flex-direction: column;
}
.apiKeyHeader {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
.keyValue {
  display: flex;
  padding: 0.688rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex: 1 0 0;
  border-radius: 0 0.25rem 0.25rem 0;
  border: 0.063rem solid var(--neutral400);
  border-left: 0;
  background-color: var(--neutral200);
}
.actions {
  display: flex;
  gap: 0.313rem;
  justify-content: center;
  align-items: center;
}

.createApiKeyContainer {
  display: flex;
  gap: 4rem;
  flex-direction: column;
  padding: 1rem 1.5rem;
  border-radius: 0.25rem;
  border: 0.063rem solid var(--neutral400);
}
.createApiKey {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
}
.createNewApiKey {
  display: flex;
  gap: 2.438rem;
}
.createApiKeyWarningContainer {
  display: none;
  position: relative;
  gap: 8;
  width: 34.125rem;
  padding: 0.5rem 1rem;
  align-items: center;
  background-color: var(--warning100);
  border: 0.063rem solid var(--warning100);
  border-radius: 4;
}
.warningIcon {
  width: 24;
  height: 24;
  margin-bottom: auto;
}
.revokeText {
  display: flex;
  flex-direction: column;
}
