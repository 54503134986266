
.modal-container {
  padding: 0 !important;
}
.modal-content {
  padding: 1.875rem;
}
.pc-approvel-required {
  background-color: #ffeab6 !important;
}
.access-request-img {
  margin-left: 35%;
}
.approval-required-header {
  color: #8c670b !important;
  font-weight: 600 !important;
  font-size: 1.25rem !important;
  line-height: 1.875rem !important;
}
.approval-required-text {
  color: #8c670b !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}
.access-request-header {
  color: #0a85c7;
  font-size: 2.125rem;
  font-weight: 600;
}
.ar-button-container {
  background: var(--md-ref-palette-neutral-200, #dfe9ef);
  padding: 0.938rem;
}
.pc-approval-error {
  background-color: #fac1c1 !important;
}
.unsuccessful-header {
  color: #651717 !important;
  font-weight: 600 !important;
  font-size: 1.25rem !important;
  line-height: 1.875rem !important;
}
.unsuccessful-text {
  color: #651717 !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}
.unsuccessful-link-container {
  width: 14.375rem;
  border-bottom: 0.063rem solid #0a85c7;
  padding-bottom: 0.625rem;
  margin-bottom: 2.5rem;
  margin-left: 40%;
  cursor: pointer;
}
.unsuccessful-link {
  color: #0a85c7 !important;
  font-size: 1.375rem !important;
  font-weight: 600 !important;
}
.unsuccessful-arrow-icon {
  margin-bottom: -0.313rem !important;
}
.approval-success-header {
  color: #195119 !important;
  font-weight: 600 !important;
  font-size: 1.25rem !important;
  line-height: 1.875rem !important;
}
.pc-request-success {
  background: var(--md-ref-palette-success-100, #c3ecc2);
}
.add-myservices-text {
  color: #195119 !important;
}
