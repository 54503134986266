.provisionDataMesh {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  margin-left: 0;
  width: calc(100% - 2.5rem);
}
.nameInstanceContainer,
.regionContainer,
.userAccessManagementContainer,
.setupBillingContainer {
  padding: 1rem 1.5rem;
  border-radius: 0.25rem;
  border: 0.063rem solid #b6c7d1;
}
.nameInstanceContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content max-content;
}
.sectionTitle {
  color: #0a85c7;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  font-weight: 700;
  font-family: Roboto;
}
.sectionSubheader {
  color: #02273c;
  font-weight: 300;
  font-family: Roboto Light;
  font-size: 1rem;
  line-height: 1.5rem;
}
.regionContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content max-content;
}
.regionDetailContainer {
  display: grid;
  grid-column: 1/-1;
  grid-column-gap: 1.5rem;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 4rem;
}
.boldTitle {
  font-weight: 700;
  color: #577687;
  padding-bottom: 0.5rem;
}
.userAccessManagementContainer {
  display: flex;
  grid-row-gap: 4rem;
  flex-direction: column;
}
.projectContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5rem;
}
.setupBillingContainer {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
}
.provisionButtonContainer {
  display: flex;
  justify-content: center;
}
