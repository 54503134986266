.algorisk-standard-button {
  color: #355263 !important;
  background-color: #dfe9ef !important;
  font-size: 1.125rem !important;
  font-weight: 600 !important;
  padding: 0.625rem 1.5rem !important;
}
.algorisk-premium-button {
  color: #355263 !important;
  font-size: 1.125rem !important;
  background-color: #a1e1f5 !important;
  font-weight: 600 !important;
  padding: 0.625rem 1.5rem !important;
}

.catalog-algo-risk-success-title {
  font-size: 2.125rem !important;
  font-weight: 600 !important;
  line-height: 2.25rem !important;
  color: #0a85c7 !important;
  margin-top: 1.875rem !important;
  margin-bottom: 6.25rem !important;
}
.algorisk-slide-image {
  height: 43.75rem;
  width: 31.25rem;
}

.catalog-algorisk-carousle {
  width: 80%;
  /* margin-left: 10%; */
  margin-top: 3.125rem;
}
.carousel .thumb {
  width: 5%;
  height: 3.125rem;
}
.carousel {
  text-align: center;
}
.carousel .thumb img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
