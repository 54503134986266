.BluePrismBanner {
  height: 50vh;
  background-image: url("../../../assets/BluePrismbg.png");
  margin-bottom: 1.875rem;
}

.BluePrismLogo {
  height: 25vh;
}

.LogoWrapper {
  width: max-content;
}

.BluePrismEverywhere {
  height: 4vh;
  position: relative;
  top: -2vh;
  right: 3.5vh;

  @media only screen and (max-width: 300px) {
    display: none;
  }
}

.DigitalWorkerCatalogHeader {
  font-size: 6.75vh;
  font-weight: 600;
  color: #0468b1;
  position: relative;
  top: -3vh;
  right: -5vh;

  @media only screen and (max-aspect-ratio: 1/1) {
    transform: scale(0.8);
    transform-origin: top left;
    line-height: 1;
    top: -5vh;
  }
}

.BluePrismBannerWrapper {
  display: flex;
  flex-direction: column;
  width: fit-content;
  padding: 2vh 4vh;

  @media only screen and (max-aspect-ratio: 1/1.5) {
    transform: scale(0.8);
    transform-origin: center left;
  }

  @media only screen and (max-width: 300px) {
    transform: scale(0.6);
    transform-origin: center left;
  }
}
