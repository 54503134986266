

.pc-info-icon {
  margin-left: 0.625rem !important;
  margin-top: -0.313rem !important;
}

.pc-instances-header-text {
  display: flex;
}
.pc-success-alert {
  background-color: #c3ecc2 !important;
  margin-left: 1.875rem;
  font-weight: 600 !important;
}

.PC-noAPIs {
  margin-top: 5rem;
  display: flex;
  gap: 2.5rem;
  margin-top: 6.25rem;
  padding-top: 6.25rem;
  border-top: 0.063rem solid #cbd9e1;
}

.PC-noAPIs-info {
  width: 50%;
}

.PC-noAPIs-logo {
  width: 30%;
}

.PC-noAPIs-white-button {
  font-weight: 500 !important;
  font-size: 1.25rem !important;
  border: 0.25rem solid #0a85c7 !important;
  border-radius: 0.25rem !important;
}

.PC-noAPIs-blue-button {
  font-weight: 500 !important;
  font-size: 1.25rem !important;
  border: 0.25rem solid #0a85c7 !important;
  border-radius: 0.25rem !important;
  margin-left: 1.25rem !important;
}

.PC-noInstances-info {
  width: 60%;
}

.PC-noInstances {
  margin-top: 5rem;
  display: flex;
  gap: 2.5rem;
}