

.myservices-datamesh-overview {
  display: flex;
  flex-direction: column;
  gap: 2.438rem;
}

.myservices-datamesh-plan-details {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.myservices-datamesh-overview-headline {
  color: #0a85c7 !important;
  line-height: 2.25rem;
  padding: 0.625rem 0;
  font-size: 2.125rem !important;
  font-weight: 600 !important;
}

.myservices-datamesh-overview-titles {
  color: #000000;
  line-height: 1.5rem;
  font-size: 1.5rem !important;
}

.myservices-datamesh-plan-details {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  flex: 100%;
  flex-wrap: wrap;
  box-shadow: none !important;
}

.myservices-datamesh-my-plan {
  height: 15.313rem;
  box-shadow:
    0 0.063rem 0.313rem rgba(2, 39, 60, 0.2),
    0 0.188rem 0.063rem rgba(2, 39, 60, 0.12),
    0 0.125rem 0.125rem rgba(2, 39, 60, 0.14);
  border: 0.063rem solid #cbd9e1;
  border-radius: 0.5rem !important;
  padding-bottom: 0.625rem;
  margin-right: 1.563rem;
}

.myservices-datamesh-my-plan-title {
  display: inline-block;
  padding-top: 0.313rem;
  font-size: 1.25rem !important;
  font-weight: 500 !important;
}

.myservices-datamesh-my-plan-button {
  color: #f6fdff;
  display: inline-block;
  margin-left: 0.75rem !important;
  background-color: #577687 !important;
  font-weight: 500 !important;
  font-size: 1.25rem !important;
  padding-top: 0.063rem !important;
  padding-bottom: 0.063rem !important;
  border-radius: 0.25rem !important;
}

.myservices-datamesh-my-plan-price {
  font-weight: 500 !important;
  font-size: 3rem !important;
  display: inline-block !important;
  margin-top: 0.813rem !important;
}

.myservices-datamesh-my-plan-price-plan {
  display: inline-block;
  color: #355263;
  font-size: 1rem !important;
  font-weight: 400 !important;
}

.myservices-datamesh-my-plan-divider {
  height: 2rem;
  color: #cbd9e1;
}

.myservices-datamesh-my-plan-availability {
  display: flex;
  align-items: flex-end;
}

.myservices-datamesh-my-plan-tb-availability {
  font-size: 1rem !important;
  font-weight: 500 !important;
}

.myservices-datamesh-my-plan-tb-progress {
  margin-top: 0.625rem;
  height: 0.313rem;
  border-radius: 0.313rem;
  margin: 0.313rem 0;
}

.myservices-datamesh-my-plan-tb-progress-des {
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  color: #355263;
}

.myservices-datamesh-my-plan-hours-progress {
  margin-top: 0.625rem;
  height: 0.313rem;
  border-radius: 0.313rem;
  margin: 0.313rem 0;
  color: #6200ee;
}

.myservices-datamesh-my-plan-manage-btn {
  margin-top: 3.125rem !important;
  margin-left: 2.5rem !important;
}

.myservices-datamesh-next-payment {
  width: 25rem;
  height: 15.313rem;
  box-shadow:
    0 0.063rem 0.313rem rgba(2, 39, 60, 0.2),
    0 0.188rem 0.063rem rgba(2, 39, 60, 0.12),
    0 0.125rem 0.125rem rgba(2, 39, 60, 0.14) !important;
  border: 0.063rem solid #cbd9e1 !important;
  border-radius: 0.5rem !important;
  padding-bottom: 0.625rem !important;
  margin-right: 1.563rem;
}

.myservices-datamesh-next-payment-card-content {
  display: flex !important;
  flex-direction: column !important;
  gap: 0.75rem !important;
}

.myservices-datamesh-next-payment-title {
  margin-bottom: 1.563rem !important;
  color: #75909f;
  font-size: 1rem !important;
  font-weight: 400;
}

.myservices-datamesh-next-payment-download {
  padding: 0.5rem;
  font-size: 0.75rem !important;
  color: #0a85c7;
  text-decoration-line: underline;
  font-weight: 600 !important;
}

.myservices-datamesh-next-payment-billing {
  display: flex;
  justify-content: space-between;
}

.myservices-datamesh-next-payment-billing-btn {
  font-size: 1.25rem !important;
  border: 0.25rem solid #0a85c7 !important;
  letter-spacing: 0.0015625rem;
  padding: 0.75rem 1.5rem !important;
  font-weight: 600 !important;
}

.metricsCard {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 2.5rem;
  border-radius: 0.875rem;
  padding: 1.5rem;
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
}
