.MS-Algorisk-title {
  color: #0a85c7 !important;
}

.MS-Algorisk-sub-title {
  margin-bottom: 3.125rem !important;
  margin-top: 1.875rem !important;
}

.ms-algorisk-plan {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.25rem;
  margin-top: -1.25rem;
  gap: 1.25rem;
  flex: 100%;
  flex-wrap: wrap;
  box-shadow: none;
  width: max-content;
}

.ms-algorisk-my-plan {
  box-shadow:
    0 0.063rem 0.313rem rgba(2, 39, 60, 0.2),
    0 0.188rem 0.063rem rgba(2, 39, 60, 0.12),
    0 0.125rem 0.125rem rgba(2, 39, 60, 0.14);
  border: 0.063rem solid #cbd9e1;
  border-radius: 0.25rem;
  margin-right: 1.563rem;
}

.ms-algorisk-my-plan-text {
  display: inline-block;
  margin-right: 0.75rem !important;
  padding: 0.313rem 0;
}

.ms-algorisk-my-plan-price {
  font-weight: 600 !important;
  font-size: 3.125rem !important;
  display: inline-block;
  margin-left: 1.875rem;
}

.ms-algorisk-my-plan-container-top {
  display: flex;
  height: 60%;
  margin: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 0.125rem solid #cbd9e1;
}

.ms-algorisk-my-plan-container-bottom {
  display: flex;
  margin: 1.25rem;
}

.ms-algorisk-my-plan-container-bottom-text {
  font-size: 1.25rem !important;
  font-weight: 600 !important;
  padding-top: 0.625rem;
}

.ms-algorisk-my-plan-container-bottom-number {
  font-size: 1.875rem !important;
  font-weight: 600 !important;
  padding: 0.75rem !important;
  margin: 0 0.75rem !important;
  text-align: center;
  align-self: center;
  background-color: #cfe9f3;
  color: #0a85c7 !important;
  padding: 0.625rem 0.938rem 0.313rem 0.125rem;
  border-radius: 1.25rem;
}

.ms-algorisk-my-plan-container-bottom-button {
  margin-left: auto !important;
}

.ms-algorisk-payment {
  width: 25rem;
  box-shadow:
    0 0.063rem 0.313rem rgba(2, 39, 60, 0.2),
    0 0.188rem 0.063rem rgba(2, 39, 60, 0.12),
    0 0.125rem 0.125rem rgba(2, 39, 60, 0.14);
  border: 0.063rem solid #cbd9e1;
  border-radius: 0.25rem;
  /* margin-right: 1.563rem; */
}

.ms-algorisk-payment-text {
  margin-bottom: 1.25rem !important;
  color: #75909f !important;
}

.ms-algorisk-payment-container-top {
  height: 60%;
  margin: 1.25rem;
  padding-bottom: 3.813rem;
  border-bottom: 0.125rem solid #cbd9e1;
}

.ms-algorisk-payment-container-bottom {
  height: 30%;
  margin: 1rem;
}

.ms-algorisk-payment-billing-button {
  border: 0.188rem solid #0a85c7 !important;
  font-size: 1.25rem !important;
  font-weight: 600 !important;
}

.ms-algorisk-payment-download-button {
  margin-right: 1.875rem !important;
}

.MS-Algo-risk-billing-table-title {
  color: #577687 !important;
  margin-bottom: 0.625rem !important;
}

.MS-Algo-risk-billing-table-content {
  color: #02273c !important;
  margin-bottom: 2.5rem !important;
}

.MS-AlgoRisk-csv-download-button {
  font-weight: 600 !important;
  font-size: 0.938rem !important;
  border: 0.25rem solid !important;
  margin-bottom: 1.25rem !important;
  margin-right: 1.25rem !important;
}

.MS-AlgoRisk-csv-download-all-button {
  font-weight: 600 !important;
  font-size: 0.875rem !important;
  border: 0.25rem solid !important;
  margin-bottom: 1.25rem !important;
}

.MS-AlgoRisk-csv-report-title-container {
  display: flex;
  margin-bottom: 1.875rem;
  margin-top: 3.125rem;
}

.MS-AlgoRisk-csv-report-title {
  margin-top: 0.938rem !important;
  margin-right: 1.25rem !important;
}

.MS-AlgoRisk-csv-report-alert {
  width: 30%;
  background-color: #c3ecc2 !important;
}

.MS-AlgoRisk-header-container {
  width: 803;
  margin-left: 13.438rem;
  margin-top: -2.188rem;
}

.MS-AlgoRisk-csv-report-total-container {
  display: flex;
  margin-bottom: 1.875rem;
}

.MS-AlgoRisk-total-text {
  margin-left: 1.25rem !important;
}

.MS-AlgoRisk-total-number {
  margin-left: 0.313rem !important;
}

.reports-table-container {
  margin-bottom: 7rem;
}
