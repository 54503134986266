.provisionSuccessfulContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  align-self: start;
  color: #0a85c7;
  font-weight: 600;
  line-height: 2.25rem;
  font-size: 2.125rem;
}

.divider {
  padding-top: 3rem;
  width: 100%;
}

.checkmark {
  margin-top: 4.0625rem;
  margin-bottom: 2.1875rem;
  color: #37b036;
}

.successTitle {
  color: var(--md-sys-color-Surface-on-surface-color-high, #0a85c7);
  font-family: Roboto;
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.25rem;
}

.successSubtext {
  color: var(--md-sys-color-Surface-on-surface-high, #02273c);
  text-align: center;
  font-family: Roboto;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.009375rem;
  margin-bottom: 2.125rem;
  margin-top: 0.3125rem;
  max-width: 62.5rem;
}

.successBoldTitle {
  color: #000;
  font-family: Roboto;
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.25rem;
  margin-bottom: 1.5rem;
}

.link {
  justify-self: center;
  grid-column: 1/-1;
}

.buttonContainer {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-row-gap: 1.375rem;
  grid-column-gap: 1rem;
}
