.manageListingContainer {
  background-color: var(--neutral50);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .heading {
    font-size: 2.125rem;
    font-weight: 600;
    color: var(--primary);
    margin-right: 1rem;
    margin-top: 0.313rem;
  }

  .manageListingDividerDiv {
    height: 1.375rem;
    padding-top: 0.625rem;
  }

  .contentSection {

    .apiServicesHeadingSection {
      align-items: flex-end;
      padding-bottom: 0.625rem;

      .heading {
        font-weight: 400;
        color: var(--neutralBlack);
        line-height: 1.5rem;
        font-size: 1.5rem;
      }

      .totalOf {
        font-weight: 400;
        color: var(--neutral500);
        line-height: 1.25rem;
        font-size: 0.875rem;
      }

      .totalOfCount {
        font-weight: 700;
        color: var(--primary);
        line-height: 1.25rem;
        font-size: 0.875rem;
        font-family: "Roboto Bold";
        margin-left: 0.313rem;
      }
    }

    .tableContainer {
      .pendingApiTable {
        padding-top: 1.5rem;
        overflow: scroll;

        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 0.3125rem;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 0.25rem;
          background-color: rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 0.0625rem rgba(255, 255, 255, 0.5);
        }
      }

      .yourListingTable {
        padding-top: 4.25rem;
        overflow: scroll;

        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 0.3125rem;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 0.25rem;
          background-color: rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 0.0625rem rgba(255, 255, 255, 0.5);
        }
      }
    }

    .versionText {
      line-height: 1.5rem;
      font-size: 0.875rem;
      font-family: "Roboto Medium";
    }

    .manageListingWarningAlert {
      background-color: #ffeab6;
      font-size: 1rem !important;
      color: #6b4f08 !important;
    }
  }
}
