.myservices-private-api {
  margin-left: 1.063rem !important;
  box-shadow: none !important;
  background-color: inherit;
}

.myservices-private-api-header {
  padding: 0;
}

.myservices-private-api-logo {
  width: 8.125rem;
  height: 5.625rem;
  margin-right: 1.25rem;
  margin-left: -0.625rem;
}

.myservices-private-api-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.myservices-private-api-function {
  background-color: #e7f3f9 !important;
  color: #0a85c7 !important;
}

.myservices-private-api-industry {
  background-color: #efe6fd !important;
  color: #6200ee !important;
}

.myservices-private-api-service-type {
  background-color: #ffe5f0 !important;
  color: #d72f77 !important;
}

.myservices-private-api-header-div {
  display: flex;
  margin-top: 1.25rem;
  gap: 0.5rem;
}

.myservices-private-api-tab-panel {
  margin-top: 4.5rem;
  padding: 0;
}

.myservices-settings {
  gap: 3rem;
  flex-direction: column;
  margin-left: 1.25rem;
  width: calc(100% - 2.5rem);
}

.myservices-settings-content {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
}

.myservices-setting-header {
  margin-right: 1rem;
  margin-top: 0.313rem;
  font-size: 2.125rem !important;
  font-weight: 600 !important;
  line-height: 1;
  color: #0468b1;
}

.myservices-setting-titles {
  margin-right: 1rem;
  margin-top: 0.313rem;
  font-size: 1.25rem !important;
  line-height: 1.5rem;
  font-weight: 600 !important;
  color: #02273c;
}

.myservices-APIkey-table-container {
  margin-bottom: 1.25rem;
  border: none;
}

.myservices-APIkey-table {
  border-bottom: 0.063rem primary;
}

.myservices-APIkey-tablehead-row {
  background-color: #0a85c7;
  height: 3rem;
}

.myservices-APIkey-tablehead-cells {
  color: #f6fdff !important;
  box-shadow:
    inset 0 -0.125rem 0 #075e8d,
    inset -0.125rem 0 0 #b3d9ee;
}

.myservices-APIkey-tablebody-row {
  border-left: 0.125rem solid #c0ebf8;
}

.myservices-APIkey-tablebody-cells {
  padding: 0.625rem 1.875rem;
  border: 0.00625rem solid #c0ebf8;
  box-shadow:
    inset 0 -0.063rem 0 #c0ebf8,
    inset -0.063rem 0 0 #c0ebf8;
}

.myservices-datamesh-instance-id {
  display: flex;
  padding: 0.688rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex: 1 0 0;
  border-radius: 0 0.25rem 0.25rem 0;
  border: 0.063rem solid #b6c7d1;
  border-left: 0;
  background-color: #dfe9ef;
}

.myservices-datamesh-id {
  display: flex;
  align-items: flex-end;
}

.myservices-datamesh-id-icon {
  padding: 0.5rem 1rem !important;
  border-radius: 0.25rem 0 0 0.25rem !important;
  border: 0.125rem solid #0a85c7 !important;
  background-color: #f6fdff !important;
}

.myservices-APIkey-tablebody-button-container {
  display: flex;
  gap: 0.313rem;
  justify-content: center;
  align-items: center;
}

.myservices-API-key-copy-icon {
  color: var(--neutral600);
}
