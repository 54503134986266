.requestExampleContainer {
  background-color: var(--neutral800);
  padding: 0 1.3125rem 6rem 1.3125rem;
  height: 100%;
}
.rightTitle {
  font-size: 1.25rem;
  color: var(--neutralWhite);
  margin: 1rem 0;
}
.requestType,
.requestUrl {
  padding: 0.75rem 1rem;
  color: white;
  font-size: 0.875rem;
}
.requestType {
  background-color: var(--primary);
  border-radius: 0.25rem 0 0 0.25rem;
}
.requestUrl {
  background-color: var(--neutral600);
  border-radius: 0 0.25rem 0.25rem 0;
  width: 100%;
}
.requestContainer {
  display: flex;
  margin-bottom: 1rem;
}

.readOnlyEditor {
  background-color: var(--neutral900);
  color: white;
}
.displayContent {
  background-color: var(--neutral900);
  color: white;
  grid-column-gap: 2rem;
  display: grid;
  grid-template-columns: max-content 1fr;
  max-height: 21.25rem;
  overflow-x: clip;
  padding: 1rem 1.5rem;
  overflow-y: auto;
  overflow-wrap: anywhere;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
}
.editorContent {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 0;
  background-color: var(--neutral900);
  color: white;
  max-height: 100%;
  padding: 1rem 1.5rem;
  align-items: end;
  width: 100%;
  box-sizing: border-box;
}
.editorContent pre {
  max-width: 100%;
  overflow: auto;
  margin: 0;
  max-height: 18.75rem;
}
.Mui-selected {
  color: #5badd9 !important;
}
.readOnlyEditor button.MuiButtonBase-root {
  color: #355263;
}
.downloadCopyContainer {
  display: flex;
  grid-column: 1/-1;
  justify-self: end;
  margin-bottom: 1.375rem;
  position: sticky;
  background-color: var(--neutral900);
}
.copyContainer {
  display: contents;
  cursor: pointer;
  font-size: 0.875rem;
  cursor: pointer;
  padding-left: 1.5rem;
}
.copyIcon {
  cursor: pointer;
  width: 2.125rem;
  color: white;
  height: 1.25rem;
  margin-right: 0.5rem;
  margin-left: 1.5rem;
}
