.tryItNow {
    display: grid;
    grid-column-gap: 1.5rem;
    grid-template-columns: 60% 40%;
}

.hr {
    display: flex;
    height: 0.063rem;
    width: 100%;
    grid-column: 1/-1;
    padding: 0 1.313rem;
    border: 0.063rem solid #cbd9e1;
}