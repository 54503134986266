.Datamesh-feature-list {
  font-weight: 600;
  line-height: 3.125rem;
}
.Datamesh-feature-details {
  font-weight: 700;
  font-size: 0.875rem;
  color: var(--md-sys-color-surface-on-surface-low, #75909f);
  margin-top: -0.625rem;
}
.Datamesh-supported-database-icons {
  width: 4.375rem;
}

.algorisk-slide-image {
  height: 100%;
  width: 100%;
}

.catalog-algorisk-carousle {
  width: 80%;
  margin-left: 8%;
  margin-top: 3.125rem;
}

.carousel .thumb {
  width: 5%;
  height: 3.125rem;
}

.carousel {
  text-align: center;
}
.carousel .thumb img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.catalog-tableview-service {
  display: flex;
}

.card-footer {
  margin-top: auto;
}

.card-desc {
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.card-title {
  width: 90%;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.gap1rem {
  gap: 1rem;
}

.gap2rem {
  gap: 2rem;
}

.gap3rem {
  gap: 3rem;
}

.gap4rem {
  gap: 4rem;
}

.paddingTop4rem {
  padding-top: 4rem;
}

.marginTop1rem {
  margin-top: 1rem;
}

.marginTop2rem {
  margin-top: 2rem;
}

.alignItems-center {
  align-items: center;
}

.justifyContent-center {
  justify-content: center;
}