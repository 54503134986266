/* Roboto-Regular */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/Roboto/Roboto-Regular.ttf");
}

/* Roboto-Medium */
@font-face {
  font-family: "Roboto Medium";
  font-style: normal;
  src: url("./assets/fonts/Roboto/Roboto-Medium.ttf");
}

/* Roboto-Bold */
@font-face {
  font-family: "Roboto Bold";
  font-style: bold;
  src: url("./assets/fonts/Roboto/Roboto-Bold.ttf");
}

/* Roboto-Light */
@font-face {
  font-family: "Roboto Light";
  font-style: light;
  src: url("./assets/fonts/Roboto/Roboto-Light.ttf");
}

body {
  margin: 0;
  background-color: #f6fdff;
  color: #02273c;
  font-family: "Roboto", sans-serif;
  line-height: 24px;
}
.MuiTab-root {
  text-transform: none !important;
}
